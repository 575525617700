import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm._show,"width":"500","persistent":""}},[_c(VCard,[_c(VCardText,[_c('div',{staticClass:"d-flex justify-center pt-3 pb-3 text--darken-1"},[_vm._v(" Login As ")]),_c('hr'),_c(VRow,{staticClass:"d-flex justify-center pb-10 mt-5"},[_c(VCol,{staticStyle:{"cursor":"pointer"},attrs:{"lg":"3","md":"3","sm":"3","align":"center"},on:{"click":function($event){return _vm.closeDialog('lab')}}},[_c(VImg,{attrs:{"src":require("@/assets/lab-icon.png"),"max-width":"65"}}),_c('div',[_vm._v("Lab")])],1),_c(VCol,{attrs:{"lg":"3","md":"3","sm":"3","align":"center"}},[_c(VImg,{attrs:{"src":require("@/assets/hospital-icon-gray.png"),"max-width":"65"}}),_c('div',[_vm._v("Hospital")])],1),_c(VCol,{attrs:{"lg":"3","md":"3","sm":"3","align":"center"}},[_c(VImg,{attrs:{"src":require("@/assets/doctor-icon-gray.png"),"max-width":"65"}}),_c('div',[_vm._v("Doctor")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }