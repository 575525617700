//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from "vuex";
import DialogSelectRole from "@/components/Dialog/DialogSelectRole";
import getEnv from "@/utils/env";
export default {
  name: "SelectRoleGroup",
  components: {
    DialogSelectRole
  },
  computed: {
    isDevEnv() {
      return getEnv("NODE_ENV") == "development";
    }
  },
  data: () => ({
    dialogSelectRole: true
  }),
  methods: {
    ...mapActions({
      checkMnemonicSomeAddress: "substrate/checkMnemonicSomeAddress"
    }),
    actionAlert(openRole) {
      this.$router.push("/".concat(openRole));
    }
  }
};