import { VApp } from 'vuetify/lib/components/VApp';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c(VMain,{staticClass:"login-main"},[_c(VContainer,{attrs:{"fill-height":""}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c(VRow,{staticClass:"justify-center"},[_c(VCol,{attrs:{"cols":"12","lg":"5","md":"5","sm":"5","align":"center"}},[_c(VImg,{attrs:{"src":require("@/assets/debio-logo-words-grey.png")}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"12","md":"12","sm":"12","align":"center"}},[_c('div',{staticClass:"white--text text-lg-h6 mt-3"},[_vm._v(" The Privacy-First Platform for Personal Genetic Testing ")])])],1)],1)],1)],1),_c('DialogSelectRole',{attrs:{"show":_vm.dialogSelectRole},on:{"toggle":function($event){_vm.dialogSelectRole = $event},"close":function (ref) {
	var openRole = ref.openRole;

	return _vm.actionAlert(openRole);
}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }